/* Footer */
.footer-wrapper {
  flex-shrink: 0;
  position: relative;
  z-index: 3;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 6.5rem;
}

.footer-wrapper a {
  color: var(--color-black);
  text-decoration: none;
}

.footer-wrapper .mobile-options {
  font-size: var(--font-size-bigger);
  font-weight: var(--font-weight-medium);
}

.footer-wrapper a:hover,
.footer-wrapper a:focus {
  color: var(--color-violet-400);
}

@media (min-width: 1024px) {
  .footer-wrapper {
    max-width: 60vw !important;
    padding-bottom: 0.75rem;
  }
}

@media only screen and (max-width: 768px) and (min-height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .footer-wrapper {
    padding-bottom: 2%;
  }
}
