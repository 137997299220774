* {
  margin: 0;
  padding: 0;
}

/* loader */
.gtok-loader {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("assets/svgs/loader_1.svg") center no-repeat;
  animation: spinnerRound 1s linear infinite;
}

@keyframes spinnerRound {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* section-one-mobile-view */
.section-one-people-mobile-wrapper .left-block .header {
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--spacing-2);
}

.section-one-people-mobile-wrapper .col {
  padding: 0;
  clear: both;
  content: "";
  display: block;
  height: auto;
}

.section-one-people-mobile-wrapper #center-block-para {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}

.section-one-people-mobile-wrapper .btn {
  font-family: 'Quicksand', sans-serif !important;
}

.swiper-slide {
  margin-top: var(--spacing-4);
  opacity: 0.99;
  filter: blur(2px);
}

.swiper-slide .img-feeling {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 1rem;
  text-align: center;
  color: var(--color-white);
  font-size: var(--font-size);
}

.swiper-slide-active {
  opacity: 1;
  filter: blur(0);
}

/* section-one */
.section-one-wrapper .left-block {
  text-align: left;
  display: table-cell;
}

.section-one-wrapper .left-block .header {
  padding-top: var(--spacing-40);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-heading-1);
  letter-spacing: var(--spacing-2);
  line-height: var(--line-height-tight);
}

.section-one-wrapper .center-block .description {
  padding: var(--spacing-12) var(--spacing-64) var(--spacing-24) var(--spacing-4);
}

.section-one-wrapper #center-block-para {
  font-family: 'Quicksand', sans-serif;
  font-weight: var(--font-weight-semi-bold);
  margin-left: 8.1%;
  min-width: 70vw;
}

.section-one-wrapper .btn {
  margin-bottom: 1rem;
}

.section-one-people-wrapper .img-base {
  padding-top: var(--spacing-32);
  position: absolute;
  z-index: 1;
}

.section-one-people-wrapper .row {
  margin-top: var(--spacing-112);
  margin-bottom: var(--spacing-112);
}

.section-one-people-wrapper .col {
  position: relative;
  z-index: 2;
  padding: 0;
  clear: both;
  content: "";
  display: block;
  height: auto;
  transform: scale(1);
  transition: 0.7s ease-in-out;
}

.section-one-people-wrapper .col:hover {
  transform: scale(1.09);
  transition: 0.7s ease-in-out;
  position: relative;
  z-index: 5;
}

.section-one-people-wrapper .col .img-feeling {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 1.5rem;
  text-align: center;
  color: var(--color-white);
  font-size: var(--font-size-bigger);
}

/* ipad view */
@media only screen and (max-width: 767.98px) {
  .section-one-wrapper {
    padding: 0;
  }

  .section-one-wrapper .left-block {
    padding: var(--spacing-48) var(--spacing-32) 0;
    text-align: center;
  }

  .section-one-wrapper .left-block .header {
    font-size: var(--font-size-heading-1);
    line-height: var(--spacing-48);
    font-weight: var(--font-weight-black);
  }

  .section-one-wrapper .left-block .description {
    line-break: strict;
    justify-content: flex-start;
    font-size: var(--font-size-big);
  }
}

/* section-two */
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.img-fluid {
  border-radius: 10%;
  width: var(--image-width-mobile);
  opacity: 1;
  z-index: -1;
}

.card-heading {
  font-family: 'Quicksand', sans-serif !important;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-heading-2);
}

.card-content {
  font-family: 'Quicksand', sans-serif !important;
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-big);
}

.col-5 .btn {
  font-family: 'Nunito', sans-serif !important;
}

.img-hover-zoom {
  overflow: hidden;
  border-radius: 10%;

  /* box-shadow: var(--box-shadow-image); */
}

.img-hover-zoom img {
  transform: scale(1);
  transition: 0.7s ease-in-out;
}

.img-hover-zoom:hover img {
  transform: scale(1.2);
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.03, 1.03);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* section-two-mobile */
.overlay-text {
  position: absolute;
  left: 1rem;
  z-index: 3;
  color: #fff;
  opacity: 0;
  padding: var(--spacing-24);
  float: left;
  bottom: 0;
}

.overlay-content {
  display: flex;
  position: relative;
}

.overlay-content .overlay-text {
  opacity: 1.1;
}

.overlay-text .text {
  position: absolute;
  left: 0;
  z-index: 3;
  color: #fff;
  padding: var(--spacing-24);
  float: left;
  bottom: 0;
  font-size: var(--font-size-heading-1);
  line-height: 1.1;
  font-weight: var(--font-weight-medium);
}

/* section - two : ipad view */
@media only screen and (max-device-width: 768px) and (max-device-height: 1024px) {
  .section-two-wrapper .center-paragraph > p {
    width: 110%;
    line-height: var(--line-height-tight);
  }

  .section-two-wrapper .center-paragraph .card-heading {
    font-size: var(--font-size-heading-3);
  }

  .section-two-wrapper .center-paragraph .card-content {
    font-size: var(--font-size-big);
  }

  .overlay-text {
    left: 0;
  }

  .overlay-text .text {
    padding: var(--spacing-16);
    font-size: var(--font-size-heading-2);
  }

  .section-four-wrapper .row {
    flex-wrap: initial !important;
  }

  .section-four-wrapper .belief-paragraph {
    margin: -2rem;
  }

  .section-four-wrapper .belief-paragraph > p {
    width: 40vw;
    font-size: 1.7rem;
    line-height: normal;
  }

  .section-four-wrapper img.create_post {
    height: 80%;
    margin-top: 2rem;
    margin-left: -1rem;
    width: 50vw;
  }

  .section-five-wrapper .newsletter-overlay-text {
    top: -17%;
    left: 55% !important;
  }

  .section-five-wrapper .newsletter-form {
    width: 38vw !important;
  }
}

/* section-three */
.map-section-wrapper {
  width: 100%;
  margin-bottom: var(--spacing-176);
}

.purple-background-with-content {
  padding-top: var(--spacing-80);
  position: relative;
  z-index: 1;
}

.purple-background-desktop {
  width: 100vw;
  height: 100%;
}

.purple-background-overlay-text > p {
  font-size: var(--spacing-32);
  line-height: var(--spacing-80);
  font-family: 'Quicksand', sans-serif;
}

.section-one-wrapper .center-block p {
  line-height: var(--line-height-tight);
  letter-spacing: var(--spacing-2);
}

.purple-background-overlay-text .purple-background-bold-text {
  font-size: var(--spacing-64);
  font-family: 'Quicksand', sans-serif;
}

.purple-background-overlay-text {
  position: absolute;
  z-index: 3;
  color: #fff;
  opacity: 1.1;
  padding-top: var(--spacing-224);
  text-align: center;
  top: 0;
  word-spacing: 0.8rem;
}

.section-three-wrapper img.map {
  position: absolute;
  z-index: 2;
  width: 90vw;
  margin-left: var(--spacing-32);
  top: 45%;
}

.section-three-wrapper .feelings_on_map {
  position: absolute;
  animation: fading 50s infinite ease-in;
  opacity: 0;
  transform-origin: 100% 100%;
  z-index: 3;
}

.section-three-wrapper .feelings_on_map:nth-child(1) {
  top: 47%;
  left: 5%;
}

.section-three-wrapper .feelings_on_map:nth-child(2) {
  top: 42%;
  left: 60%;
  animation-delay: 10s;
}

.section-three-wrapper .feelings_on_map:nth-child(3) {
  top: 45%;
  left: 40%;
  transform-origin: 70% 100%;
  animation-delay: 20s;
}

.section-three-wrapper .feelings_on_map:nth-child(4) {
  top: 55%;
  left: 15%;
  transform-origin: 25% 100%;
  animation-delay: 30s;
}

.section-three-wrapper .feelings_on_map:nth-child(5) {
  top: 54%;
  left: 65%;
  transform-origin: 20% 100%;
  animation-delay: 40s;
}

img#feeling_4_on_map {
  width: 18rem;
}

img#feeling_5_on_map {
  width: 18rem;
}

@keyframes fading {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  10% {
    opacity: 1.2;
    transform: scale(1);
  }

  14% {
    opacity: 1.2;
    transform: scale(1);
  }

  30% {
    opacity: 0;
    transform: scale(0);
  }
}

.section-three-wrapper .btn {
  position: absolute;
  z-index: 3;
  bottom: 0;
  width: var(--spacing-208);
  background-color: #fff;
  margin-bottom: var(--spacing-208);
}

/* section-four */
.section-four-wrapper {
  margin-bottom: var(--spacing-176);
}

.section-four-wrapper .belief-paragraph > p {
  line-height: var(--spacing-56);
  font-family: 'Quicksand', sans-serif !important;
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--spacing-32);
}

.section-four-wrapper img.create_post {
  height: 80%;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: -5rem;
}

.section-five-wrapper {
  width: 100%;
}

.section-five-wrapper .newsletter-section {
  position: relative;
  z-index: 1;
}

.section-five-wrapper .newsletter-bg {
  width: 100vw !important;
}

.section-five-wrapper .newsletter-overlay-text > p {
  font-size: var(--spacing-32);
  line-height: var(--spacing-48);
  font-family: 'Quicksand', sans-serif;
  font-weight: var(--font-weight-semi-bold);
}

.section-five-wrapper .newsletter-overlay-text {
  position: absolute;
  color: #fff;
  opacity: 1.1;
  top: 0;
  left: 60%;
  word-spacing: var(--spacing-4);
}

.section-five-wrapper .newsletter-form {
  position: absolute;
  width: 30vw;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.section-five-wrapper .input-email-box {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 1rem;
  color: black;
}

.section-five-wrapper .newsletter-submit-button {
  display: flex;
  width: 10rem;
  border-radius: 50px;
  background-color: #8006a8 !important;
  font-family: 'Nunito', sans-serif;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 0 10px 1px rgba(0, 0, 0, 0.25);
}

.section-five-wrapper .newsletter-subscription-success {
  position: relative;
  padding: 0.4rem;
  margin: 5rem;
  margin-top: 6rem !important;
  color: #8006a8;
  text-align: center;
  font-size: small !important;
  z-index: 3;
}

@media only screen and (max-width: 768px) and (max-height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .purple-background-overlay-text {
    padding-top: var(--spacing-144) !important;
  }

  .section-three-wrapper img.map {
    position: absolute;
    z-index: 3;
    width: 90vw;

    /* margin-top: var(--spacing-432) !important; */
    margin-left: var(--spacing-32);

    /* top: 0; */
  }

  .section-three-wrapper .feelings_on_map:nth-child(1) {
    top: 38% !important;
    left: 15% !important;
  }

  .section-three-wrapper .feelings_on_map:nth-child(2) {
    top: 38% !important;
    left: 60% !important;
    animation-delay: 10s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(3) {
    top: 45% !important;
    left: 35% !important;
    animation-delay: 20s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(4) {
    top: 50% !important;
    left: 8% !important;
    animation-delay: 30s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(5) {
    top: 40% !important;
    left: 35% !important;
    animation-delay: 40s;
  }

  .section-three-wrapper .btn {
    left: 39%;
    margin-bottom: var(--spacing-160);
  }

  .last-paragraph-text > p {
    padding-top: var(--spacing-48) !important;
  }

  /* .section-four-wrapper .row{
    flex-wrap: initial !important;
  } */

  /* .section-four-wrapper .belief-paragraph {
   margin: -2rem;
  } */

  /* .section-four-wrapper .belief-paragraph > p{
    width: 40vw;
    font-size: 1.7rem;
    line-height: normal;
  } */

  /*
  .section-four-wrapper img.create_post {
    height: 80%;
    margin-top: 2rem;
    margin-left: -1rem;
    width: 50vw;
  } */

  .section-five-wrapper .newsletter-overlay-text {
    top: -17%;
    left: 55% !important;
  }

  .section-five-wrapper .newsletter-form {
    width: 38vw !important;
  }
}

@media only screen
  and (max-width: 1024px)
  and (max-height: 1366px)
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .purple-background-overlay-text {
    padding-top: var(--spacing-176);
  }

  .section-three-wrapper img.map {
    position: absolute;
    z-index: 3;
    width: 90vw;
    margin-left: var(--spacing-32);
  }

  .section-three-wrapper .feelings_on_map:nth-child(1) {
    top: 37%;
    left: 15%;
  }

  .section-three-wrapper .feelings_on_map:nth-child(2) {
    top: 37%;
    left: 60%;
    animation-delay: 10s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(3) {
    top: 45%;
    left: 38%;
    animation-delay: 20s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(4) {
    top: 53%;
    left: 8%;
    animation-delay: 30s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(5) {
    top: 50%;
    left: 44%;
    animation-delay: 40s;
  }

  .section-four-wrapper .belief-paragraph {
    margin: -2rem;
  }

  .section-four-wrapper .belief-paragraph > p {
    width: 35vw;
    font-size: 1.7rem;
    line-height: var(--spacing-48) !important;
  }

  .section-four-wrapper img.create_post {
    margin-left: 0;
    width: 50vw;
  }

  .section-five-wrapper .newsletter-overlay-text {
    position: absolute;
    color: #fff;
    opacity: 1.1;
    left: 60%;
    word-spacing: var(--spacing-4);
  }

  .section-five-wrapper .newsletter-overlay-text > p {
    width: 38vw;
  }

  .section-five-wrapper .newsletter-form {
    position: absolute;
    width: 30vw;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    top: 75%;
  }

  .section-five-wrapper .input-email-box {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 1rem;
    color: black;
  }

  .section-five-wrapper .newsletter-submit-button {
    display: flex;
    width: 10rem;
    border-radius: 50px;
    background-color: #8006a8 !important;
    font-family: 'Nunito', sans-serif;
    justify-content: center;
    align-items: center;
    box-shadow: -1px 0 10px 1px rgba(0, 0, 0, 0.25);
  }
}

/* section-three: mobile */

.map-section-mobile-wrapper {
  width: 100%;
  margin-bottom: var(--spacing-96);
}

.mobile .purple-background {
  height: 74vh;
  width: 100vw;
  position: relative;
}

.mobile .purple-background-overlay-text > p {
  margin-top: -4rem;
  font-size: 0.93rem;
  line-height: var(--spacing-48);
}

.mobile .purple-background-overlay-text .purple-background-bold-text {
  font-size: 1.9rem;
  font-family: 'Quicksand', sans-serif;
}

.mobile .purple-background-overlay-text {
  position: absolute;
  z-index: 3;
  color: #fff;
  opacity: 1.1;
  padding-top: var(--spacing-200) !important;
  text-align: center;
  top: 0;
  word-spacing: 0.1rem;
}

.mobile .section-three-wrapper .btn {
  position: absolute;
  z-index: 3;
  width: var(--spacing-128);
  height: 1.6rem;
  border-radius: 30px;
  padding-top: 0.2rem;
  font-size: 0.75rem;
  background-color: #fff;
  margin-bottom: var(--spacing-112);
  left: 34% !important;
  bottom: 5%;
}

.mobile .section-three-wrapper img.map {
  position: absolute;
  z-index: 2;
  width: 90vw;

  /* margin-top: var(--spacing-352); */
  margin-left: var(--spacing-16);
  top: 43% !important;
}

.mobile .section-three-wrapper .feelings_on_map_mobile {
  position: absolute;
  animation: fading-mobile 60s infinite ease-in;
  opacity: 0;
  transform-origin: 50% 100%;
  z-index: 3;
}

.mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(1) {
  top: 40%;
  left: 20%;
}

.mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(2) {
  top: 40%;
  left: 35%;
  animation-delay: 12s;
}

.mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(3) {
  top: 40%;
  left: 20%;
  animation-delay: 24s;
}

.mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(4) {
  top: 40%;
  left: 10%;
  animation-delay: 36s;
}

.mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(5) {
  top: 45%;
  left: 30%;
  animation-delay: 48s;
}

.mobile .last-paragraph-text-mobile > p {
  font-family: 'Quicksand', sans-serif !important;
  position: absolute;
  text-align: center;
  z-index: 3;
  margin-bottom: var(--spacing-56);
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: var(--spacing-64);
}

@keyframes fading-mobile {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  10% {
    opacity: 1.2;
    transform: scale(1);
  }

  12% {
    opacity: 1.2;
    transform: scale(1);
  }

  20% {
    opacity: 0;
    transform: scale(0);
  }
}

/* MEDIA QUERIES */
@media only screen and (max-device-width: 320px) {
  .mobile .purple-background {
    height: 100vh;
    margin-bottom: -10rem;
  }

  .mobile .section-three-wrapper img.map {
    width: 95vw;

    /* margin-top: var(--spacing-368) !important; */
    margin-left: 0.4rem;
    top: 0;
  }

  .mobile .purple-background-overlay-text > p {
    margin-top: -3rem;
    font-size: 0.8rem;
    line-height: var(--spacing-48);
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(2) {
    top: 43%;
    left: 2%;
    animation-delay: 12s;
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(5) {
    top: 45%;
    left: 30%;
    animation-delay: 48s;
  }

  .mobile .section-three-wrapper .btn {
    left: 31% !important;
    top: 73%;
  }

  .mobile .last-paragraph-text-mobile {
    padding-top: var(--spacing-160);
    padding-bottom: var(--spacing-48) !important;
  }

  .belief-paragraph-mobile {
    margin-top: 15rem;
  }

  /* .section-five-mobile-wrapper .newsletter-form-mobile {
    margin-left: 1% !important;
    top: 50%;
  } */

  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: -0.3rem !important;
    width: 90vw;
  }

  .mobile .newsletter-overlay-text-mobile {
    font-size: small !important;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    width: 15rem !important;
    height: 1.4rem !important;
    margin-left: 8% !important;
    margin-top: 0.5rem;
  }

  .section-five-mobile-wrapper .input-email-box-mobile {
    padding: 0.8rem !important;
    font-size: smaller !important;
    color: var(--color-black);
  }

  .section-five-mobile-wrapper .newsletter-submit-button-mobile {
    font-size: small;
    width: 7rem !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) {
  .mobile .purple-background {
    height: 72vh;
  }

  .mobile .section-three-wrapper img.map {
    width: 95vw;

    /* margin-top: var(--spacing-336) !important; */
    margin-left: 0.4rem;
    top: 0;
  }

  .mobile .purple-background-overlay-text > p {
    margin-top: -3rem;
    font-size: 0.93rem;
    line-height: var(--spacing-48);
  }

  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: 0.6rem !important;
    width: 85vw;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    margin-left: 12% !important;
  }
}

@media only screen and (min-width: 360px) and (max-height: 640px) {
  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: -0.1rem !important;
    width: 90vw;
  }
}

@media only screen and (min-width: 540px) and (min-height: 720px) {
  .mobile .section-three-wrapper .btn {
    left: 38% !important;
  }

  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: 1.27rem !important;
    width: 85vw;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    margin-left: 21% !important;
  }
}

@media only screen and (max-width: 280px) and (min-height: 653px) {
  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(1) {
    top: 40%;
    left: 20%;
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(2) {
    top: 40%;
    left: 25%;
    animation-delay: 12s;
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(3) {
    top: 40%;
    left: 10%;
    animation-delay: 24s;
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(4) {
    top: 40%;
    left: 10%;
    animation-delay: 36s;
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(5) {
    top: 45%;
    left: 20%;
    animation-delay: 48s;
  }

  .mobile .newsletter-overlay-text-mobile {
    font-size: small !important;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    width: 15rem !important;
    height: 1.2rem !important;
    margin-top: 0.5rem;
    left: -1%;
  }

  .section-five-mobile-wrapper .input-email-box-mobile {
    padding: 0.65rem !important;
    font-size: var(--font-size-smaller) !important;
  }

  .section-five-mobile-wrapper .newsletter-submit-button-mobile {
    font-size: var(--font-size-small);
    width: 5rem !important;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) {
  .mobile .section-three-wrapper img.map {
    width: 95vw; /* margin-top: var(--spacing-224) !important; */
    margin-left: 0.4rem;
    top: 10%;
  }

  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: 0.8rem !important;
    width: 85vw;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    margin-left: 12% !important;
  }
}

@media only screen and (min-device-width: 414px) and (min-device-height: 736px) {
  .mobile .section-three-wrapper img.map {
    width: 95vw; /* margin-top: var(--spacing-320) !important; */
    margin-left: 0.4rem;
    top: 0;
  }

  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: 0.8rem !important;
    width: 85vw;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    margin-left: 12% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .minwidth-1200 {
    display: none;
  }

  .maxwidth-1199 {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .minwidth-1200 {
    display: none;
  }

  .maxwidth-1199 {
    display: block;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .minwidth-1200 {
    display: block;
  }

  .maxwidth-1199 {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .maxwidth-1199 {
    display: block;
  }
}

.section-five-mobile-wrapper {
  width: 100%;
  margin-bottom: var(--spacing-96);
}

.section-five-mobile-wrapper.mobile .newsletter-extra-spacing {
  background-color: #e7ac2a;
  height: var(--spacing-128) !important;
  width: 100vw;
}

.section-five-mobile-wrapper.mobile .newsletter-section-mobile {
  width: 100vw;
  position: relative;
}

.section-five-mobile-wrapper.mobile .newsletter-bg-mobile {
  width: 100vw;
}

.section-five-mobile-wrapper.mobile .newsletter-overlay-text-para-mobile > p {
  margin-top: -4rem;
  line-height: var(--spacing-48);
}

.section-five-mobile-wrapper.mobile .newsletter-overlay-text-mobile {
  position: absolute;
  z-index: 3;
  color: var(--color-white);
  opacity: 1.1;
  text-align: center;
  top: 10%;
  word-spacing: 0.1rem;
  font-size: var(--font-size-bigger);
}

.section-five-mobile-wrapper .newsletter-overlay-text {
  position: absolute;
  color: #fff;
  opacity: 1.1;
  top: 0;
  left: 60%;
  word-spacing: var(--spacing-4);
}

.section-five-mobile-wrapper .newsletter-form-mobile {
  position: absolute;
  width: 18rem;
  height: 2rem;
  display: flex;
  margin-left: 8%;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
  background-color: var(--color-white);
}

.section-five-mobile-wrapper .input-email-box-mobile {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 1rem;
  font-size: small;
  color: var(--color-black);
}

.section-five-mobile-wrapper .newsletter-submit-button-mobile {
  display: flex;
  width: 10rem;
  border-radius: 50px;
  background-color: #8006a8 !important;
  font-family: 'Nunito', sans-serif;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 0 10px 1px rgba(0, 0, 0, 0.25);
}
