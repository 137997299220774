.question {
  position: relative;
  background: rgba(108, 35, 255, 0.1);
  align-items: center;
  padding: 15px 15px 15px 20px;
  margin: 0;
  display: block;
  width: 100%;
  border-radius: var(--spacing-8);
  cursor: pointer;
}

.answers {
  background: #efefef;
  padding: 0 15px;
  margin: 0 0 10px 0;
  max-height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.7s ease;
  -moz-transition: 0.7s ease;
  -o-transition: 0.7s ease;
  transition: 0.7s ease;
}

.plus {
  font-size: 2em;
  float: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.questions {
  display: none;
}

.questions:checked ~ .answers {
  max-height: 50vh;
  opacity: 1;
  padding: 15px;
}

.questions:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
