button.btn-submit {
  background-color: #6930db;
  color: white;
  border-radius: 100px;
  width: 10rem;
}

button.btn-submit:hover {
  background-color: #492296;
  color: white;
}
