.catalysts-activities-wrapper {
  color: var(--color-white);
  background: radial-gradient(64.92% 58.09% at 50.04% 79.16%, #9a30db 1.5%, #41129e 100%);
  border-radius: var(--spacing-24);
  margin: var(--spacing-12) 0;
  position: relative;
}

.catalysts-activities-wrapper .close {
  position: absolute;
  right: 3%;
  top: 4%;
}

.catalysts-activities-wrapper .text {
  padding: var(--spacing-64);
}

.catalysts-activities-wrapper .header {
  font-family: 'Quicksand', sans-serif !important;
}

.catalysts-activities-wrapper .header-key {
  padding: var(--spacing-12) 0 0;
  font-family: 'Quicksand', sans-serif !important;
  font-weight: var(--font-weight-bold);
}

.catalysts-activities-wrapper .header-value {
  font-family: 'Quicksand', sans-serif !important;
  font-weight: 100;
  font-size: var(--font-size-bigger);
}

.catalysts-activities-wrapper .header-img {
  width: 80%;
}

.catalysts-activities-wrapper h1.header-mobile.pt-4 {
  position: absolute;
  left: 25%;
  font-size: var(--spacing-40);
}

.catalysts-activities-wrapper .text-center {
  padding: var(--spacing-40) !important;
}

.catalysts-activities-wrapper .close-mobile {
  position: absolute;
  right: 6%;
  top: 2%;
}

/* Media Queries */
@media only screen and (max-width: 280px) and (min-height: 653px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 20%;
  }
}

@media only screen and (min-width: 540px) and (min-height: 720px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 35% !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 31%;
  }
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 26%;
  }
}

@media only screen and (min-device-width: 414px) and (min-device-height: 736px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 29%;
  }
}

@media only screen and (max-device-width: 320px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 21%;
  }
}
