:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray-100: #f7f8fa;
  --color-gray-200: #f1f2f5;
  --color-gray-300: #e4e6eb;
  --color-gray-400: #bdbfc7;
  --color-gray-500: #8f94a2;
  --color-gray-600: #757a8a;
  --color-gray-650: #696e7c;
  --color-gray-700: #4f5461;
  --color-gray-800: #2c3242;
  --color-gray-900: #1b1f29;
  --color-blue-100: #ecf2ff;
  --color-blue-200: #d6e3ff;
  --color-blue-300: #bdd2ff;
  --color-blue-400: #175fff;
  --color-blue-500: #1043b2;
  --color-blue-transparent: rgba(236, 242, 255, 0.5);
  --color-violet-100: #f4effd;
  --color-violet-400: #6930db;
  --color-yellow-100: #fffbd8;
  --color-yellow-400: #f9cb40;
  --color-orange-100: #ffeed1;
  --color-orange-400: #ffa201;
  --color-orange-600: #ff7a1a;
  --color-red-100: #fff2f2;
  --color-red-200: #ffd3d3;
  --color-red-300: #ffbaba;
  --color-red-400: #cd3636;
  --color-red-500: #ae1d1d;
  --color-green-100: #e2f8ed;
  --color-green-200: #c5f2dc;
  --color-green-400: #3dd48a;
  --color-green-500: #36ba79;
  --color-green-600: #1f6e48;
  --color-primary-100: var(--color-blue-100);
  --color-primary-200: var(--color-blue-200);
  --color-primary-300: var(--color-blue-300);
  --color-primary-400: var(--color-blue-400);
  --color-primary-500: var(--color-blue-500);
  --color-text-dark: var(--color-gray-900);
  --color-text: var(--color-gray-800);
  --color-text-light: var(--color-gray-650);
  --color-text-disabled: var(--color-gray-400);
  --color-line: var(--color-gray-300);
  --color-line-light: var(--color-gray-200);
  --color-icon-fill: var(--color-gray-600);
  --color-button-primary-background: var(--color-primary-400);
  --color-button-primary-background-hover: var(--color-primary-500);
  --color-button-primary-text: var(--color-white);
  --color-button-primary-text-hover: var(--color-white);
  --color-button-secondary-text: var(--color-primary-400);
  --color-button-secondary-border: var(--color-gray-300);
  --color-button-secondary-background: var(--color-white);
  --color-button-secondary-text-hover: var(--color-primary-400);
  --color-button-secondary-background-hover: var(--color-primary-100);
  --color-button-text: var(--color-white);
  --color-button-text-hover: var(--color-white);
  --color-button-background: var(--color-primary-400);
  --color-button-background-hover: var(--color-primary-500);
  --color-menu-background-hover: var(--color-gray-200);
  --color-menu-item-highlight: var(--color-gray-300);
  --transition-duration: 0.2s;
  --transition-duration-long: 0.4s;
  --spacing-1: 0.0625rem;
  --spacing-2: 0.125rem;
  --spacing-4: 0.25rem;
  --spacing-8: 0.5rem;
  --spacing-12: 0.75rem;
  --spacing-16: 1rem;
  --spacing-24: 1.5rem;
  --spacing-32: 2rem;
  --spacing-40: 2.5rem;
  --spacing-48: 3rem;
  --spacing-56: 3.5rem;
  --spacing-64: 4rem;
  --spacing-80: 5rem;
  --spacing-96: 6rem;
  --spacing-112: 7rem;
  --spacing-128: 8rem;
  --spacing-144: 9rem;
  --spacing-160: 10rem;
  --spacing-176: 11rem;
  --spacing-200: 12.5rem;
  --spacing-208: 13rem;
  --spacing-224: 14rem;
  --spacing-304: 19rem;
  --spacing-320: 20rem;
  --spacing-336: 21rem;
  --spacing-352: 22rem;
  --spacing-368: 23rem;
  --spacing-416: 26rem;
  --spacing-432: 27rem;
  --spacing-512: 32rem;
  --spacing-560: 35rem;
  --spacing-212: 38rem;
  --page-padding: var(--spacing-24);
  --page-padding-small: var(--spacing-16);
  --border-radius-small: 0.25rem;
  --border-radius: 0.5rem;
  --border-radius-big: 0.75rem;
  --image-width-mobile: 40rem;
  --input-height: var(--spacing-48);
  --input-height-small: var(--spacing-40);
  --input-height-tiny: var(--spacing-32);
  --input-border-radius: var(--border-radius-small);
  --button-height-small: var(--input-height-small);
  --button-height: var(--input-height);
  --button-border-radius: var(--input-border-radius);
  --font-family: 'Inter', -apple-system, blinkmacsystemfont, 'Segoe UI', sans-serif;
  --font-family-display: 'Larsseit', -apple-system, blinkmacsystemfont, 'Segoe UI', sans-serif;
  --font-size-smaller: 0.625rem;
  --font-size-small: 0.75rem;
  --font-size: 0.875rem;
  --font-size-big: 1rem;
  --font-size-bigger: 1.125rem;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;
  --font-size-heading-1: 2.6rem;
  --font-size-heading-2: 1.7rem;
  --font-size-heading-3: var(--font-size-bigger);
  --font-size-heading-4: var(--font-size-big);
  --line-height: 1.6;
  --line-height-tight: 1.25;
  --line-height-tighter: 1rem;
  --line-height-para: 2rem;
  --box-shadow: 0 0.0625rem 0.125rem rgba(44, 50, 66, 0.08);
  --box-shadow-long: 0 0 0.25rem rgba(44, 50, 66, 0.08), 0 0.25rem 1rem rgba(44, 50, 66, 0.08);
  --box-shadow-panel: 0 0 0.125rem 0.0625rem rgba(44, 50, 66, 0.08);
  --box-shadow-error: 0 0.0625rem 0.125rem var(--color-red-300);
  --box-shadow-image: -3px 15px 40px -9px rgba(0, 0, 0, 0.75);
  --z-index-header: 10;
  --z-index-skip-nav: 20;
  --z-index-events-popup: 2147483004;
  --z-index-tickets-bar: 2147483024;
  --z-index-tickets: 2147483034;
  --z-index-modal: 2147483044;
  --avatar-size-tiny: 1.5rem;
  --avatar-size-small: 2rem;
  --avatar-size-medium: 2.5rem;
  --avatar-size-large: 3rem;
  --avatar-size-huge: 3.375rem;
  --avatar-size-gigantic: 4rem;
  --button-size-small: 1.5rem;
  --button-size-medium: 2rem;
  --button-size-large: 3rem;
  --dash-nav-width: 16rem;
  --dash-nav-width-v2: 15rem;
  --color-tooltip: var(--color-white);
  --color-tooltip-background: var(--color-black);
  --tooltip-tail-size: 0.625rem;
  --tooltip: 100;
}

* {
  font-family: 'Poppins', sans-serif;
  line-height: 28px;
  letter-spacing: 0.005rem;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

.text-primary {
  color: rgba(220, 53, 69, 0.9) !important;
}

.text-violet {
  color: var(--color-violet-400);
}

.alert-violet {
  color: var(--color-violet-400);
  background-color: var(--color-violet-100);
  border-color: var(--color-violet-100);
}

.btn {
  box-shadow: none !important;
}

.btn-violet {
  color: var(--color-white) !important;
  background-color: var(--color-violet-400) !important;
}

.btn-violet-v2 {
  color: var(--color-white) !important;
  width: 9rem;
  height: 2rem;
  border-radius: 25px !important;
  padding-bottom: 1.7rem !important;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  background-image: -webkit-linear-gradient(#7c3bff, #2e087a);
  font-weight: 200 !important;
  margin-bottom: 3rem;
  font-size: 0.9rem !important;
}

.btn-violet-v2-small {
  color: var(--color-white) !important;
  width: 8rem;
  height: 2.3rem;
  border-radius: 25px !important;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  background-image: -webkit-linear-gradient(#7c3bff, #2e087a);

  /* font-family: 'Nunito', sans-serif !important; */
  font-weight: 100 !important;
  margin-bottom: 3rem;
}

.btn-violet-v3 {
  color: var(--color-white) !important;
  width: 9.25rem;
  height: 2.75rem;
  border-radius: 25px !important;
  padding-top: 0.6rem !important;
  background-image: -webkit-linear-gradient(#6930db, #6930db);
  font-weight: 200 !important;
  margin-bottom: 3rem;
  font-size: 0.9rem !important;
}

.btn-violet-v3-small {
  color: var(--color-white) !important;
  width: 8rem;
  height: 2.3rem;
  border-radius: 25px !important;
  background-image: -webkit-linear-gradient(#6930db, #6930db);

  /* font-family: 'Nunito', sans-serif !important; */
  font-weight: 100 !important;
  margin-bottom: 3rem;
}

.btn-violet-outline {
  color: var(--color-violet-400) !important;
  border-color: var(--color-violet-400) !important;
  width: 8rem;
  height: 2.3rem;
  border-radius: 25px !important;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #fff !important;
  font-family: 'Nunito', sans-serif !important;
  font-weight: bold !important;
}

.cookie-banner {
  font-size: var(--font-size);
  position: fixed;
  bottom: 0;
  padding: 0.4em 0.9em 1.4em;
  border: 1px solid #eee;
  align-items: center;
  background-color: #fff;
  z-index: 999;
}

.pointer {
  cursor: pointer;
}
