.mobile-footer-wrapper .fixed-bottom {
  width: 100vw;
  background: var(--color-white);
  transition: 0.5s all ease-in-out;
}

.mobile-footer-wrapper {
  bottom: 0;
  width: 100vw;
  background-color: var(--color-white);
  padding: var(--spacing-16);
  text-align: center;
  z-index: 99;
  box-shadow: 0 0 10px  #ccc !important;
  margin-bottom: -3rem !important;
}

@media only screen and (max-width: 280px) {
  .mobile-footer-wrapper .btn {
    width: 7rem !important;
  }
}
