.icon-heart {
  width: var(--spacing-24);
  height: var(--spacing-24);
}

.icon-heart.active {
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
}

.scaleInImgFollow {
  animation: scaleIn 0.75s 2;
}

.scaleInImgUnfollow {
  animation: scaleIn 0.75s 1;
}

@-webkit-keyframes scaleIn {
  0% {
    transform: scale(1);
    filter: none;
  }

  50% {
    transform: scale(1.7);
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
  }

  100% {
    transform: scale(1);
    filter: none;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(1);
    filter: none;
  }

  50% {
    transform: scale(1.7);
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
  }

  100% {
    transform: scale(1);
    filter: none;
  }
}

.follow-blog {
  text-align: center;
  cursor: pointer;
  padding-top: var(--spacing-3);
}

.follow-blog .follower-length {
  color: #f42222;
  position: relative;
  top: -7px;
  left: var(--spacing-12);
  font-size: var(--font-size-bigger);
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
}
