@import url(https://fonts.googleapis.com/css2?family=Playball&family=Poppins&family=Quicksand:wght@500;600;700&family=Nunito&display=swap);
:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray-100: #f7f8fa;
  --color-gray-200: #f1f2f5;
  --color-gray-300: #e4e6eb;
  --color-gray-400: #bdbfc7;
  --color-gray-500: #8f94a2;
  --color-gray-600: #757a8a;
  --color-gray-650: #696e7c;
  --color-gray-700: #4f5461;
  --color-gray-800: #2c3242;
  --color-gray-900: #1b1f29;
  --color-blue-100: #ecf2ff;
  --color-blue-200: #d6e3ff;
  --color-blue-300: #bdd2ff;
  --color-blue-400: #175fff;
  --color-blue-500: #1043b2;
  --color-blue-transparent: rgba(236, 242, 255, 0.5);
  --color-violet-100: #f4effd;
  --color-violet-400: #6930db;
  --color-yellow-100: #fffbd8;
  --color-yellow-400: #f9cb40;
  --color-orange-100: #ffeed1;
  --color-orange-400: #ffa201;
  --color-orange-600: #ff7a1a;
  --color-red-100: #fff2f2;
  --color-red-200: #ffd3d3;
  --color-red-300: #ffbaba;
  --color-red-400: #cd3636;
  --color-red-500: #ae1d1d;
  --color-green-100: #e2f8ed;
  --color-green-200: #c5f2dc;
  --color-green-400: #3dd48a;
  --color-green-500: #36ba79;
  --color-green-600: #1f6e48;
  --color-primary-100: var(--color-blue-100);
  --color-primary-200: var(--color-blue-200);
  --color-primary-300: var(--color-blue-300);
  --color-primary-400: var(--color-blue-400);
  --color-primary-500: var(--color-blue-500);
  --color-text-dark: var(--color-gray-900);
  --color-text: var(--color-gray-800);
  --color-text-light: var(--color-gray-650);
  --color-text-disabled: var(--color-gray-400);
  --color-line: var(--color-gray-300);
  --color-line-light: var(--color-gray-200);
  --color-icon-fill: var(--color-gray-600);
  --color-button-primary-background: var(--color-primary-400);
  --color-button-primary-background-hover: var(--color-primary-500);
  --color-button-primary-text: var(--color-white);
  --color-button-primary-text-hover: var(--color-white);
  --color-button-secondary-text: var(--color-primary-400);
  --color-button-secondary-border: var(--color-gray-300);
  --color-button-secondary-background: var(--color-white);
  --color-button-secondary-text-hover: var(--color-primary-400);
  --color-button-secondary-background-hover: var(--color-primary-100);
  --color-button-text: var(--color-white);
  --color-button-text-hover: var(--color-white);
  --color-button-background: var(--color-primary-400);
  --color-button-background-hover: var(--color-primary-500);
  --color-menu-background-hover: var(--color-gray-200);
  --color-menu-item-highlight: var(--color-gray-300);
  --transition-duration: 0.2s;
  --transition-duration-long: 0.4s;
  --spacing-1: 0.0625rem;
  --spacing-2: 0.125rem;
  --spacing-4: 0.25rem;
  --spacing-8: 0.5rem;
  --spacing-12: 0.75rem;
  --spacing-16: 1rem;
  --spacing-24: 1.5rem;
  --spacing-32: 2rem;
  --spacing-40: 2.5rem;
  --spacing-48: 3rem;
  --spacing-56: 3.5rem;
  --spacing-64: 4rem;
  --spacing-80: 5rem;
  --spacing-96: 6rem;
  --spacing-112: 7rem;
  --spacing-128: 8rem;
  --spacing-144: 9rem;
  --spacing-160: 10rem;
  --spacing-176: 11rem;
  --spacing-200: 12.5rem;
  --spacing-208: 13rem;
  --spacing-224: 14rem;
  --spacing-304: 19rem;
  --spacing-320: 20rem;
  --spacing-336: 21rem;
  --spacing-352: 22rem;
  --spacing-368: 23rem;
  --spacing-416: 26rem;
  --spacing-432: 27rem;
  --spacing-512: 32rem;
  --spacing-560: 35rem;
  --spacing-212: 38rem;
  --page-padding: var(--spacing-24);
  --page-padding-small: var(--spacing-16);
  --border-radius-small: 0.25rem;
  --border-radius: 0.5rem;
  --border-radius-big: 0.75rem;
  --image-width-mobile: 40rem;
  --input-height: var(--spacing-48);
  --input-height-small: var(--spacing-40);
  --input-height-tiny: var(--spacing-32);
  --input-border-radius: var(--border-radius-small);
  --button-height-small: var(--input-height-small);
  --button-height: var(--input-height);
  --button-border-radius: var(--input-border-radius);
  --font-family: 'Inter', -apple-system, blinkmacsystemfont, 'Segoe UI', sans-serif;
  --font-family-display: 'Larsseit', -apple-system, blinkmacsystemfont, 'Segoe UI', sans-serif;
  --font-size-smaller: 0.625rem;
  --font-size-small: 0.75rem;
  --font-size: 0.875rem;
  --font-size-big: 1rem;
  --font-size-bigger: 1.125rem;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;
  --font-size-heading-1: 2.6rem;
  --font-size-heading-2: 1.7rem;
  --font-size-heading-3: var(--font-size-bigger);
  --font-size-heading-4: var(--font-size-big);
  --line-height: 1.6;
  --line-height-tight: 1.25;
  --line-height-tighter: 1rem;
  --line-height-para: 2rem;
  --box-shadow: 0 0.0625rem 0.125rem rgba(44, 50, 66, 0.08);
  --box-shadow-long: 0 0 0.25rem rgba(44, 50, 66, 0.08), 0 0.25rem 1rem rgba(44, 50, 66, 0.08);
  --box-shadow-panel: 0 0 0.125rem 0.0625rem rgba(44, 50, 66, 0.08);
  --box-shadow-error: 0 0.0625rem 0.125rem var(--color-red-300);
  --box-shadow-image: -3px 15px 40px -9px rgba(0, 0, 0, 0.75);
  --z-index-header: 10;
  --z-index-skip-nav: 20;
  --z-index-events-popup: 2147483004;
  --z-index-tickets-bar: 2147483024;
  --z-index-tickets: 2147483034;
  --z-index-modal: 2147483044;
  --avatar-size-tiny: 1.5rem;
  --avatar-size-small: 2rem;
  --avatar-size-medium: 2.5rem;
  --avatar-size-large: 3rem;
  --avatar-size-huge: 3.375rem;
  --avatar-size-gigantic: 4rem;
  --button-size-small: 1.5rem;
  --button-size-medium: 2rem;
  --button-size-large: 3rem;
  --dash-nav-width: 16rem;
  --dash-nav-width-v2: 15rem;
  --color-tooltip: var(--color-white);
  --color-tooltip-background: var(--color-black);
  --tooltip-tail-size: 0.625rem;
  --tooltip: 100;
}

* {
  font-family: 'Poppins', sans-serif;
  line-height: 28px;
  letter-spacing: 0.005rem;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

.text-primary {
  color: rgba(220, 53, 69, 0.9) !important;
}

.text-violet {
  color: #6930db;
  color: var(--color-violet-400);
}

.alert-violet {
  color: #6930db;
  color: var(--color-violet-400);
  background-color: #f4effd;
  background-color: var(--color-violet-100);
  border-color: #f4effd;
  border-color: var(--color-violet-100);
}

.btn {
  box-shadow: none !important;
}

.btn-violet {
  color: #fff !important;
  color: var(--color-white) !important;
  background-color: #6930db !important;
  background-color: var(--color-violet-400) !important;
}

.btn-violet-v2 {
  color: #fff !important;
  color: var(--color-white) !important;
  width: 9rem;
  height: 2rem;
  border-radius: 25px !important;
  padding-bottom: 1.7rem !important;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  background-image: -webkit-linear-gradient(#7c3bff, #2e087a);
  font-weight: 200 !important;
  margin-bottom: 3rem;
  font-size: 0.9rem !important;
}

.btn-violet-v2-small {
  color: #fff !important;
  color: var(--color-white) !important;
  width: 8rem;
  height: 2.3rem;
  border-radius: 25px !important;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  background-image: -webkit-linear-gradient(#7c3bff, #2e087a);

  /* font-family: 'Nunito', sans-serif !important; */
  font-weight: 100 !important;
  margin-bottom: 3rem;
}

.btn-violet-v3 {
  color: #fff !important;
  color: var(--color-white) !important;
  width: 9.25rem;
  height: 2.75rem;
  border-radius: 25px !important;
  padding-top: 0.6rem !important;
  background-image: -webkit-linear-gradient(#6930db, #6930db);
  font-weight: 200 !important;
  margin-bottom: 3rem;
  font-size: 0.9rem !important;
}

.btn-violet-v3-small {
  color: #fff !important;
  color: var(--color-white) !important;
  width: 8rem;
  height: 2.3rem;
  border-radius: 25px !important;
  background-image: -webkit-linear-gradient(#6930db, #6930db);

  /* font-family: 'Nunito', sans-serif !important; */
  font-weight: 100 !important;
  margin-bottom: 3rem;
}

.btn-violet-outline {
  color: #6930db !important;
  color: var(--color-violet-400) !important;
  border-color: #6930db !important;
  border-color: var(--color-violet-400) !important;
  width: 8rem;
  height: 2.3rem;
  border-radius: 25px !important;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #fff !important;
  font-family: 'Nunito', sans-serif !important;
  font-weight: bold !important;
}

.cookie-banner {
  font-size: 0.875rem;
  font-size: var(--font-size);
  position: fixed;
  bottom: 0;
  padding: 0.4em 0.9em 1.4em;
  border: 1px solid #eee;
  align-items: center;
  background-color: #fff;
  z-index: 999;
}

.pointer {
  cursor: pointer;
}

.careers-wrapper {
  text-align: left;
  text-align: justify;
}

.careers-wrapper .page-title {
  padding: var(--spacing-32) 0 var(--spacing-16);
}

.careers-wrapper .section .section-title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-bigger);
  padding-bottom: var(--spacing-8);
}

.tnc-wrapper {
  text-align: left;
  text-align: justify;
}

.tnc-wrapper .page-title {
  padding: var(--spacing-32) 0 var(--spacing-16);
}

.tnc-wrapper .section {
  margin-bottom: var(--spacing-16);
}

.tnc-wrapper .section .section-title {
  font-weight: var(--font-weight-bold);
}

.privacy-policy-wrapper {
  text-align: left;
  text-align: justify;
}

.privacy-policy-wrapper .page-title {
  padding: var(--spacing-32) 0 var(--spacing-16);
}

.privacy-policy-wrapper .section .section-title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-bigger);
  padding-bottom: var(--spacing-8);
}

* {
  margin: 0;
  padding: 0;
}

/* loader */
.gtok-loader {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(/static/media/loader_1.48956150.svg) center no-repeat;
  -webkit-animation: spinnerRound 1s linear infinite;
          animation: spinnerRound 1s linear infinite;
}

@-webkit-keyframes spinnerRound {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spinnerRound {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

/* section-one-mobile-view */
.section-one-people-mobile-wrapper .left-block .header {
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--spacing-2);
}

.section-one-people-mobile-wrapper .col {
  padding: 0;
  clear: both;
  content: "";
  display: block;
  height: auto;
}

.section-one-people-mobile-wrapper #center-block-para {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}

.section-one-people-mobile-wrapper .btn {
  font-family: 'Quicksand', sans-serif !important;
}

.swiper-slide {
  margin-top: var(--spacing-4);
  opacity: 0.99;
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.swiper-slide .img-feeling {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 1rem;
  text-align: center;
  color: var(--color-white);
  font-size: var(--font-size);
}

.swiper-slide-active {
  opacity: 1;
  -webkit-filter: blur(0);
          filter: blur(0);
}

/* section-one */
.section-one-wrapper .left-block {
  text-align: left;
  display: table-cell;
}

.section-one-wrapper .left-block .header {
  padding-top: var(--spacing-40);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-heading-1);
  letter-spacing: var(--spacing-2);
  line-height: var(--line-height-tight);
}

.section-one-wrapper .center-block .description {
  padding: var(--spacing-12) var(--spacing-64) var(--spacing-24) var(--spacing-4);
}

.section-one-wrapper #center-block-para {
  font-family: 'Quicksand', sans-serif;
  font-weight: var(--font-weight-semi-bold);
  margin-left: 8.1%;
  min-width: 70vw;
}

.section-one-wrapper .btn {
  margin-bottom: 1rem;
}

.section-one-people-wrapper .img-base {
  padding-top: var(--spacing-32);
  position: absolute;
  z-index: 1;
}

.section-one-people-wrapper .row {
  margin-top: var(--spacing-112);
  margin-bottom: var(--spacing-112);
}

.section-one-people-wrapper .col {
  position: relative;
  z-index: 2;
  padding: 0;
  clear: both;
  content: "";
  display: block;
  height: auto;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: 0.7s ease-in-out;
}

.section-one-people-wrapper .col:hover {
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
  transition: 0.7s ease-in-out;
  position: relative;
  z-index: 5;
}

.section-one-people-wrapper .col .img-feeling {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 1.5rem;
  text-align: center;
  color: var(--color-white);
  font-size: var(--font-size-bigger);
}

/* ipad view */
@media only screen and (max-width: 767.98px) {
  .section-one-wrapper {
    padding: 0;
  }

  .section-one-wrapper .left-block {
    padding: var(--spacing-48) var(--spacing-32) 0;
    text-align: center;
  }

  .section-one-wrapper .left-block .header {
    font-size: var(--font-size-heading-1);
    line-height: var(--spacing-48);
    font-weight: var(--font-weight-black);
  }

  .section-one-wrapper .left-block .description {
    line-break: strict;
    justify-content: flex-start;
    font-size: var(--font-size-big);
  }
}

/* section-two */
.align-items-center {
  align-items: center !important;
}

.d-flex {
  display: flex !important;
}

.img-fluid {
  border-radius: 10%;
  width: var(--image-width-mobile);
  opacity: 1;
  z-index: -1;
}

.card-heading {
  font-family: 'Quicksand', sans-serif !important;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-heading-2);
}

.card-content {
  font-family: 'Quicksand', sans-serif !important;
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-big);
}

.col-5 .btn {
  font-family: 'Nunito', sans-serif !important;
}

.img-hover-zoom {
  overflow: hidden;
  border-radius: 10%;

  /* box-shadow: var(--box-shadow-image); */
}

.img-hover-zoom img {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: 0.7s ease-in-out;
}

.img-hover-zoom:hover img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

@-webkit-keyframes zoom-in-zoom-out {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.03, 1.03);
            transform: scale(1.03, 1.03);
  }

  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.03, 1.03);
            transform: scale(1.03, 1.03);
  }

  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

/* section-two-mobile */
.overlay-text {
  position: absolute;
  left: 1rem;
  z-index: 3;
  color: #fff;
  opacity: 0;
  padding: var(--spacing-24);
  float: left;
  bottom: 0;
}

.overlay-content {
  display: flex;
  position: relative;
}

.overlay-content .overlay-text {
  opacity: 1.1;
}

.overlay-text .text {
  position: absolute;
  left: 0;
  z-index: 3;
  color: #fff;
  padding: var(--spacing-24);
  float: left;
  bottom: 0;
  font-size: var(--font-size-heading-1);
  line-height: 1.1;
  font-weight: var(--font-weight-medium);
}

/* section - two : ipad view */
@media only screen and (max-device-width: 768px) and (max-device-height: 1024px) {
  .section-two-wrapper .center-paragraph > p {
    width: 110%;
    line-height: var(--line-height-tight);
  }

  .section-two-wrapper .center-paragraph .card-heading {
    font-size: var(--font-size-heading-3);
  }

  .section-two-wrapper .center-paragraph .card-content {
    font-size: var(--font-size-big);
  }

  .overlay-text {
    left: 0;
  }

  .overlay-text .text {
    padding: var(--spacing-16);
    font-size: var(--font-size-heading-2);
  }

  .section-four-wrapper .row {
    flex-wrap: initial !important;
  }

  .section-four-wrapper .belief-paragraph {
    margin: -2rem;
  }

  .section-four-wrapper .belief-paragraph > p {
    width: 40vw;
    font-size: 1.7rem;
    line-height: normal;
  }

  .section-four-wrapper img.create_post {
    height: 80%;
    margin-top: 2rem;
    margin-left: -1rem;
    width: 50vw;
  }

  .section-five-wrapper .newsletter-overlay-text {
    top: -17%;
    left: 55% !important;
  }

  .section-five-wrapper .newsletter-form {
    width: 38vw !important;
  }
}

/* section-three */
.map-section-wrapper {
  width: 100%;
  margin-bottom: var(--spacing-176);
}

.purple-background-with-content {
  padding-top: var(--spacing-80);
  position: relative;
  z-index: 1;
}

.purple-background-desktop {
  width: 100vw;
  height: 100%;
}

.purple-background-overlay-text > p {
  font-size: var(--spacing-32);
  line-height: var(--spacing-80);
  font-family: 'Quicksand', sans-serif;
}

.section-one-wrapper .center-block p {
  line-height: var(--line-height-tight);
  letter-spacing: var(--spacing-2);
}

.purple-background-overlay-text .purple-background-bold-text {
  font-size: var(--spacing-64);
  font-family: 'Quicksand', sans-serif;
}

.purple-background-overlay-text {
  position: absolute;
  z-index: 3;
  color: #fff;
  opacity: 1.1;
  padding-top: var(--spacing-224);
  text-align: center;
  top: 0;
  word-spacing: 0.8rem;
}

.section-three-wrapper img.map {
  position: absolute;
  z-index: 2;
  width: 90vw;
  margin-left: var(--spacing-32);
  top: 45%;
}

.section-three-wrapper .feelings_on_map {
  position: absolute;
  -webkit-animation: fading 50s infinite ease-in;
          animation: fading 50s infinite ease-in;
  opacity: 0;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  z-index: 3;
}

.section-three-wrapper .feelings_on_map:nth-child(1) {
  top: 47%;
  left: 5%;
}

.section-three-wrapper .feelings_on_map:nth-child(2) {
  top: 42%;
  left: 60%;
  -webkit-animation-delay: 10s;
          animation-delay: 10s;
}

.section-three-wrapper .feelings_on_map:nth-child(3) {
  top: 45%;
  left: 40%;
  -webkit-transform-origin: 70% 100%;
          transform-origin: 70% 100%;
  -webkit-animation-delay: 20s;
          animation-delay: 20s;
}

.section-three-wrapper .feelings_on_map:nth-child(4) {
  top: 55%;
  left: 15%;
  -webkit-transform-origin: 25% 100%;
          transform-origin: 25% 100%;
  -webkit-animation-delay: 30s;
          animation-delay: 30s;
}

.section-three-wrapper .feelings_on_map:nth-child(5) {
  top: 54%;
  left: 65%;
  -webkit-transform-origin: 20% 100%;
          transform-origin: 20% 100%;
  -webkit-animation-delay: 40s;
          animation-delay: 40s;
}

img#feeling_4_on_map {
  width: 18rem;
}

img#feeling_5_on_map {
  width: 18rem;
}

@-webkit-keyframes fading {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  10% {
    opacity: 1.2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  14% {
    opacity: 1.2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  30% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes fading {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  10% {
    opacity: 1.2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  14% {
    opacity: 1.2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  30% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

.section-three-wrapper .btn {
  position: absolute;
  z-index: 3;
  bottom: 0;
  width: var(--spacing-208);
  background-color: #fff;
  margin-bottom: var(--spacing-208);
}

/* section-four */
.section-four-wrapper {
  margin-bottom: var(--spacing-176);
}

.section-four-wrapper .belief-paragraph > p {
  line-height: var(--spacing-56);
  font-family: 'Quicksand', sans-serif !important;
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--spacing-32);
}

.section-four-wrapper img.create_post {
  height: 80%;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: -5rem;
}

.section-five-wrapper {
  width: 100%;
}

.section-five-wrapper .newsletter-section {
  position: relative;
  z-index: 1;
}

.section-five-wrapper .newsletter-bg {
  width: 100vw !important;
}

.section-five-wrapper .newsletter-overlay-text > p {
  font-size: var(--spacing-32);
  line-height: var(--spacing-48);
  font-family: 'Quicksand', sans-serif;
  font-weight: var(--font-weight-semi-bold);
}

.section-five-wrapper .newsletter-overlay-text {
  position: absolute;
  color: #fff;
  opacity: 1.1;
  top: 0;
  left: 60%;
  word-spacing: var(--spacing-4);
}

.section-five-wrapper .newsletter-form {
  position: absolute;
  width: 30vw;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.section-five-wrapper .input-email-box {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 1rem;
  color: black;
}

.section-five-wrapper .newsletter-submit-button {
  display: flex;
  width: 10rem;
  border-radius: 50px;
  background-color: #8006a8 !important;
  font-family: 'Nunito', sans-serif;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 0 10px 1px rgba(0, 0, 0, 0.25);
}

.section-five-wrapper .newsletter-subscription-success {
  position: relative;
  padding: 0.4rem;
  margin: 5rem;
  margin-top: 6rem !important;
  color: #8006a8;
  text-align: center;
  font-size: small !important;
  z-index: 3;
}

@media only screen and (max-width: 768px) and (max-height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .purple-background-overlay-text {
    padding-top: var(--spacing-144) !important;
  }

  .section-three-wrapper img.map {
    position: absolute;
    z-index: 3;
    width: 90vw;

    /* margin-top: var(--spacing-432) !important; */
    margin-left: var(--spacing-32);

    /* top: 0; */
  }

  .section-three-wrapper .feelings_on_map:nth-child(1) {
    top: 38% !important;
    left: 15% !important;
  }

  .section-three-wrapper .feelings_on_map:nth-child(2) {
    top: 38% !important;
    left: 60% !important;
    -webkit-animation-delay: 10s;
            animation-delay: 10s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(3) {
    top: 45% !important;
    left: 35% !important;
    -webkit-animation-delay: 20s;
            animation-delay: 20s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(4) {
    top: 50% !important;
    left: 8% !important;
    -webkit-animation-delay: 30s;
            animation-delay: 30s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(5) {
    top: 40% !important;
    left: 35% !important;
    -webkit-animation-delay: 40s;
            animation-delay: 40s;
  }

  .section-three-wrapper .btn {
    left: 39%;
    margin-bottom: var(--spacing-160);
  }

  .last-paragraph-text > p {
    padding-top: var(--spacing-48) !important;
  }

  /* .section-four-wrapper .row{
    flex-wrap: initial !important;
  } */

  /* .section-four-wrapper .belief-paragraph {
   margin: -2rem;
  } */

  /* .section-four-wrapper .belief-paragraph > p{
    width: 40vw;
    font-size: 1.7rem;
    line-height: normal;
  } */

  /*
  .section-four-wrapper img.create_post {
    height: 80%;
    margin-top: 2rem;
    margin-left: -1rem;
    width: 50vw;
  } */

  .section-five-wrapper .newsletter-overlay-text {
    top: -17%;
    left: 55% !important;
  }

  .section-five-wrapper .newsletter-form {
    width: 38vw !important;
  }
}

@media only screen
  and (max-width: 1024px)
  and (max-height: 1366px)
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .purple-background-overlay-text {
    padding-top: var(--spacing-176);
  }

  .section-three-wrapper img.map {
    position: absolute;
    z-index: 3;
    width: 90vw;
    margin-left: var(--spacing-32);
  }

  .section-three-wrapper .feelings_on_map:nth-child(1) {
    top: 37%;
    left: 15%;
  }

  .section-three-wrapper .feelings_on_map:nth-child(2) {
    top: 37%;
    left: 60%;
    -webkit-animation-delay: 10s;
            animation-delay: 10s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(3) {
    top: 45%;
    left: 38%;
    -webkit-animation-delay: 20s;
            animation-delay: 20s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(4) {
    top: 53%;
    left: 8%;
    -webkit-animation-delay: 30s;
            animation-delay: 30s;
  }

  .section-three-wrapper .feelings_on_map:nth-child(5) {
    top: 50%;
    left: 44%;
    -webkit-animation-delay: 40s;
            animation-delay: 40s;
  }

  .section-four-wrapper .belief-paragraph {
    margin: -2rem;
  }

  .section-four-wrapper .belief-paragraph > p {
    width: 35vw;
    font-size: 1.7rem;
    line-height: var(--spacing-48) !important;
  }

  .section-four-wrapper img.create_post {
    margin-left: 0;
    width: 50vw;
  }

  .section-five-wrapper .newsletter-overlay-text {
    position: absolute;
    color: #fff;
    opacity: 1.1;
    left: 60%;
    word-spacing: var(--spacing-4);
  }

  .section-five-wrapper .newsletter-overlay-text > p {
    width: 38vw;
  }

  .section-five-wrapper .newsletter-form {
    position: absolute;
    width: 30vw;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    top: 75%;
  }

  .section-five-wrapper .input-email-box {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 1rem;
    color: black;
  }

  .section-five-wrapper .newsletter-submit-button {
    display: flex;
    width: 10rem;
    border-radius: 50px;
    background-color: #8006a8 !important;
    font-family: 'Nunito', sans-serif;
    justify-content: center;
    align-items: center;
    box-shadow: -1px 0 10px 1px rgba(0, 0, 0, 0.25);
  }
}

/* section-three: mobile */

.map-section-mobile-wrapper {
  width: 100%;
  margin-bottom: var(--spacing-96);
}

.mobile .purple-background {
  height: 74vh;
  width: 100vw;
  position: relative;
}

.mobile .purple-background-overlay-text > p {
  margin-top: -4rem;
  font-size: 0.93rem;
  line-height: var(--spacing-48);
}

.mobile .purple-background-overlay-text .purple-background-bold-text {
  font-size: 1.9rem;
  font-family: 'Quicksand', sans-serif;
}

.mobile .purple-background-overlay-text {
  position: absolute;
  z-index: 3;
  color: #fff;
  opacity: 1.1;
  padding-top: var(--spacing-200) !important;
  text-align: center;
  top: 0;
  word-spacing: 0.1rem;
}

.mobile .section-three-wrapper .btn {
  position: absolute;
  z-index: 3;
  width: var(--spacing-128);
  height: 1.6rem;
  border-radius: 30px;
  padding-top: 0.2rem;
  font-size: 0.75rem;
  background-color: #fff;
  margin-bottom: var(--spacing-112);
  left: 34% !important;
  bottom: 5%;
}

.mobile .section-three-wrapper img.map {
  position: absolute;
  z-index: 2;
  width: 90vw;

  /* margin-top: var(--spacing-352); */
  margin-left: var(--spacing-16);
  top: 43% !important;
}

.mobile .section-three-wrapper .feelings_on_map_mobile {
  position: absolute;
  -webkit-animation: fading-mobile 60s infinite ease-in;
          animation: fading-mobile 60s infinite ease-in;
  opacity: 0;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  z-index: 3;
}

.mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(1) {
  top: 40%;
  left: 20%;
}

.mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(2) {
  top: 40%;
  left: 35%;
  -webkit-animation-delay: 12s;
          animation-delay: 12s;
}

.mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(3) {
  top: 40%;
  left: 20%;
  -webkit-animation-delay: 24s;
          animation-delay: 24s;
}

.mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(4) {
  top: 40%;
  left: 10%;
  -webkit-animation-delay: 36s;
          animation-delay: 36s;
}

.mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(5) {
  top: 45%;
  left: 30%;
  -webkit-animation-delay: 48s;
          animation-delay: 48s;
}

.mobile .last-paragraph-text-mobile > p {
  font-family: 'Quicksand', sans-serif !important;
  position: absolute;
  text-align: center;
  z-index: 3;
  margin-bottom: var(--spacing-56);
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: var(--spacing-64);
}

@-webkit-keyframes fading-mobile {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  10% {
    opacity: 1.2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  12% {
    opacity: 1.2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  20% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes fading-mobile {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  10% {
    opacity: 1.2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  12% {
    opacity: 1.2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  20% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

/* MEDIA QUERIES */
@media only screen and (max-device-width: 320px) {
  .mobile .purple-background {
    height: 100vh;
    margin-bottom: -10rem;
  }

  .mobile .section-three-wrapper img.map {
    width: 95vw;

    /* margin-top: var(--spacing-368) !important; */
    margin-left: 0.4rem;
    top: 0;
  }

  .mobile .purple-background-overlay-text > p {
    margin-top: -3rem;
    font-size: 0.8rem;
    line-height: var(--spacing-48);
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(2) {
    top: 43%;
    left: 2%;
    -webkit-animation-delay: 12s;
            animation-delay: 12s;
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(5) {
    top: 45%;
    left: 30%;
    -webkit-animation-delay: 48s;
            animation-delay: 48s;
  }

  .mobile .section-three-wrapper .btn {
    left: 31% !important;
    top: 73%;
  }

  .mobile .last-paragraph-text-mobile {
    padding-top: var(--spacing-160);
    padding-bottom: var(--spacing-48) !important;
  }

  .belief-paragraph-mobile {
    margin-top: 15rem;
  }

  /* .section-five-mobile-wrapper .newsletter-form-mobile {
    margin-left: 1% !important;
    top: 50%;
  } */

  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: -0.3rem !important;
    width: 90vw;
  }

  .mobile .newsletter-overlay-text-mobile {
    font-size: small !important;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    width: 15rem !important;
    height: 1.4rem !important;
    margin-left: 8% !important;
    margin-top: 0.5rem;
  }

  .section-five-mobile-wrapper .input-email-box-mobile {
    padding: 0.8rem !important;
    font-size: smaller !important;
    color: var(--color-black);
  }

  .section-five-mobile-wrapper .newsletter-submit-button-mobile {
    font-size: small;
    width: 7rem !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) {
  .mobile .purple-background {
    height: 72vh;
  }

  .mobile .section-three-wrapper img.map {
    width: 95vw;

    /* margin-top: var(--spacing-336) !important; */
    margin-left: 0.4rem;
    top: 0;
  }

  .mobile .purple-background-overlay-text > p {
    margin-top: -3rem;
    font-size: 0.93rem;
    line-height: var(--spacing-48);
  }

  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: 0.6rem !important;
    width: 85vw;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    margin-left: 12% !important;
  }
}

@media only screen and (min-width: 360px) and (max-height: 640px) {
  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: -0.1rem !important;
    width: 90vw;
  }
}

@media only screen and (min-width: 540px) and (min-height: 720px) {
  .mobile .section-three-wrapper .btn {
    left: 38% !important;
  }

  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: 1.27rem !important;
    width: 85vw;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    margin-left: 21% !important;
  }
}

@media only screen and (max-width: 280px) and (min-height: 653px) {
  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(1) {
    top: 40%;
    left: 20%;
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(2) {
    top: 40%;
    left: 25%;
    -webkit-animation-delay: 12s;
            animation-delay: 12s;
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(3) {
    top: 40%;
    left: 10%;
    -webkit-animation-delay: 24s;
            animation-delay: 24s;
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(4) {
    top: 40%;
    left: 10%;
    -webkit-animation-delay: 36s;
            animation-delay: 36s;
  }

  .mobile .section-three-wrapper .feelings_on_map_mobile:nth-child(5) {
    top: 45%;
    left: 20%;
    -webkit-animation-delay: 48s;
            animation-delay: 48s;
  }

  .mobile .newsletter-overlay-text-mobile {
    font-size: small !important;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    width: 15rem !important;
    height: 1.2rem !important;
    margin-top: 0.5rem;
    left: -1%;
  }

  .section-five-mobile-wrapper .input-email-box-mobile {
    padding: 0.65rem !important;
    font-size: var(--font-size-smaller) !important;
  }

  .section-five-mobile-wrapper .newsletter-submit-button-mobile {
    font-size: var(--font-size-small);
    width: 5rem !important;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) {
  .mobile .section-three-wrapper img.map {
    width: 95vw; /* margin-top: var(--spacing-224) !important; */
    margin-left: 0.4rem;
    top: 10%;
  }

  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: 0.8rem !important;
    width: 85vw;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    margin-left: 12% !important;
  }
}

@media only screen and (min-device-width: 414px) and (min-device-height: 736px) {
  .mobile .section-three-wrapper img.map {
    width: 95vw; /* margin-top: var(--spacing-320) !important; */
    margin-left: 0.4rem;
    top: 0;
  }

  .section-four-mobile-wrapper .create_post_mobile {
    margin-left: 0.8rem !important;
    width: 85vw;
  }

  .section-five-mobile-wrapper .newsletter-form-mobile {
    margin-left: 12% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .minwidth-1200 {
    display: none;
  }

  .maxwidth-1199 {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .minwidth-1200 {
    display: none;
  }

  .maxwidth-1199 {
    display: block;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .minwidth-1200 {
    display: block;
  }

  .maxwidth-1199 {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .maxwidth-1199 {
    display: block;
  }
}

.section-five-mobile-wrapper {
  width: 100%;
  margin-bottom: var(--spacing-96);
}

.section-five-mobile-wrapper.mobile .newsletter-extra-spacing {
  background-color: #e7ac2a;
  height: var(--spacing-128) !important;
  width: 100vw;
}

.section-five-mobile-wrapper.mobile .newsletter-section-mobile {
  width: 100vw;
  position: relative;
}

.section-five-mobile-wrapper.mobile .newsletter-bg-mobile {
  width: 100vw;
}

.section-five-mobile-wrapper.mobile .newsletter-overlay-text-para-mobile > p {
  margin-top: -4rem;
  line-height: var(--spacing-48);
}

.section-five-mobile-wrapper.mobile .newsletter-overlay-text-mobile {
  position: absolute;
  z-index: 3;
  color: var(--color-white);
  opacity: 1.1;
  text-align: center;
  top: 10%;
  word-spacing: 0.1rem;
  font-size: var(--font-size-bigger);
}

.section-five-mobile-wrapper .newsletter-overlay-text {
  position: absolute;
  color: #fff;
  opacity: 1.1;
  top: 0;
  left: 60%;
  word-spacing: var(--spacing-4);
}

.section-five-mobile-wrapper .newsletter-form-mobile {
  position: absolute;
  width: 18rem;
  height: 2rem;
  display: flex;
  margin-left: 8%;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
  background-color: var(--color-white);
}

.section-five-mobile-wrapper .input-email-box-mobile {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 1rem;
  font-size: small;
  color: var(--color-black);
}

.section-five-mobile-wrapper .newsletter-submit-button-mobile {
  display: flex;
  width: 10rem;
  border-radius: 50px;
  background-color: #8006a8 !important;
  font-family: 'Nunito', sans-serif;
  justify-content: center;
  align-items: center;
  box-shadow: -1px 0 10px 1px rgba(0, 0, 0, 0.25);
}

/* Header */

.fixed-top {
  width: 100vw;
  background: var(--color-white);
  transition: 0.5s all ease-in-out;
}

.custom-toggler {
  box-shadow: none !important;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(105, 48, 219, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.sticky-after {
  transition: 0.5s all ease-in-out;
  box-shadow: 0 1px 0 rgba(105, 48, 219, 0.1) !important;
}

.navbar-brand,
.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--color-black);
  font-weight: 900;
  font-size: 22px !important;
}

.navbar-brand-image {
  padding: 9px;
  padding-top: 0;
}

#navbar-brand-logo {
  width: 9rem !important;
}

#navbar-brand-text {
  width: 6rem;
}

.navbar-nav a {
  color: var(--color-black);
  font-size: var(--font-size);
  transition: 0.15s all ease-in-out;
}

.navbar-nav a:hover {
  color: var(--color-violet-400);
}

.navbar-nav a:focus {
  color: var(--color-black);
}

.join-btn,
.join-btn:hover,
.join-btn:focus {
  display: block;
  padding: var(--spacing-4) var(--spacing-16);
  box-shadow: none !important;
  transition: 0.15s all ease-in-out;
  margin-bottom: -3rem;
  font-family: 'Quicksand', sans-serif !important;
}

@media (min-width: 768px) and (max-width: 1023.97px) {
  .move-right-without-join {
    margin-left: 70%;
  }

  .move-right-with-join {
    margin-left: 60%;
  }
}

@media (min-width: 1024px) and (max-width: 1199.97px) {
  .move-right-without-join {
    margin-left: 60%;
  }

  .move-right-with-join {
    margin-left: 43%;
  }
}

@media (min-width: 1200px) {
  .move-right-without-join {
    margin-left: 70%;
  }

  .move-right-with-join {
    margin-left: 60%;
  }
}

/* Footer */
.footer-wrapper {
  flex-shrink: 0;
  position: relative;
  z-index: 3;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 6.5rem;
}

.footer-wrapper a {
  color: var(--color-black);
  text-decoration: none;
}

.footer-wrapper .mobile-options {
  font-size: var(--font-size-bigger);
  font-weight: var(--font-weight-medium);
}

.footer-wrapper a:hover,
.footer-wrapper a:focus {
  color: var(--color-violet-400);
}

@media (min-width: 1024px) {
  .footer-wrapper {
    max-width: 60vw !important;
    padding-bottom: 0.75rem;
  }
}

@media only screen and (max-width: 768px) and (min-height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .footer-wrapper {
    padding-bottom: 2%;
  }
}

.question {
  position: relative;
  background: rgba(108, 35, 255, 0.1);
  align-items: center;
  padding: 15px 15px 15px 20px;
  margin: 0;
  display: block;
  width: 100%;
  border-radius: var(--spacing-8);
  cursor: pointer;
}

.answers {
  background: #efefef;
  padding: 0 15px;
  margin: 0 0 10px 0;
  max-height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  transition: 0.7s ease;
}

.plus {
  font-size: 2em;
  float: left;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  transition: 0.3s ease;
}

.questions {
  display: none;
}

.questions:checked ~ .answers {
  max-height: 50vh;
  opacity: 1;
  padding: 15px;
}

.questions:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.blogs-wrapper {
  margin-top: var(--spacing-32);
  padding: 0 var(--spacing-64);
}

.blogs-wrapper a {
  color: var(--color-black) !important;
  text-decoration: none;
}

#navbar-brand-logo-blog {
  width: 9rem !important;
}

.blog-footer a {
  text-decoration: none;
  color: #fff;
}

.blog-footer a:hover {
  color: #808080;
}

.d-flex .small {
  margin-top: -1rem;
}

.blog-header .navbar-nav a {
  margin-top: 0.5rem;
}

.blogs-wrapper .blog-img {
  border-radius: var(--spacing-32);
}

.blogs-wrapper .blog-img.pinned {
  width: 100% !important;
  object-fit: cover;
}

.blogs-wrapper .btn-violet-outline {
  width: -webkit-fit-content;
  width: fit-content;
  outline: none;
  color: black !important;
  border: none;
}

.blogs-wrapper .blog-category {
  border-radius: var(--spacing-16);
  font-family: 'Quicksand', sans-serif !important;
  font-size: var(--font-size-bigger);
  font-weight: bolder;
}

.blogs-wrapper .blog-header {
  cursor: pointer;
  font-weight: bolder;
  font-family: 'Quicksand', sans-serif !important;

  /* font-size: 2rem !important; */
}

.blogs-wrapper .card {
  border: 0 solid;
  border-radius: var(--spacing-16);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  transition: -webkit-transform 100ms ease-in;
  transition: transform 100ms ease-in;
  transition: transform 100ms ease-in, -webkit-transform 100ms ease-in;
}

.blogs-wrapper .card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.blogs-wrapper .card .blog-img {
  max-height: 17rem !important;
  object-fit: cover;
  border-radius: var(--spacing-16) var(--spacing-16) 0 0;
}

.blog-footer-section-wrapper {
  width: 100%;
  margin-top: var(--spacing-208);
  position: static;
  flex-shrink: 0;
}

.blog-footer-background-with-content {
  position: relative;
  z-index: 1;
}

.blog-footer-background-desktop {
  width: 100vw;
  height: 100%;
}

.blog-footer-links {
  line-height: var(--spacing-32);
}

.blog-footer-links:hover {
  color: var(--color-white) !important;
}

.blog-footer .company-name {
  font-size: var(--font-size-small);
  margin-top: var(--spacing-12);
}

.blog-footer .follow-us {
  font-size: var(--font-size-bigger);
}

.blog-footer .social-icon {
  padding-left: var(--spacing-24);
}

.blog-footer .social-icon img {
  width: var(--spacing-24);
  height: var(--spacing-24);
}

.blog-footer {
  position: absolute;
  z-index: 3;
  color: #fff;
  opacity: 1.1;
  text-align: left;
  top: 25%;
  left: 15%;
  width: 35vw;
}

.blog-footer .right-wrapper {
  text-align: right;
}

#blog-cta {
  color: #fff !important;
  margin-left: 35vw;
  margin-right: 35vw;
}

.navbar-brand-logo {
  padding-top: var(--spacing-24) !important;
}

.blogs-wrapper .col-3 .d-flex {
  position: sticky;
  top: 8%;
}

.blogs-wrapper .row .col-sm-4 .card {
  align-self: center !important;
}

.badge-category {
  position: absolute;
  left: 4%;
  top: 6%;
  width: 3rem;
}

/* Progress bar */
#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  top: 14%;
  left: 0;
}

#progressBar {
  background: linear-gradient(to left, #7c3bff, #7c3bff);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  opacity: 0;
}

#progressBarContainer,
#progressBar {
  height: 10px;
}

.footer-section-mobile-wrapper {
  width: 100%;
  position: relative;
}

.mobile .blog-footer-background-mobile {
  margin-top: 9rem;
  height: 75vh;
  width: 100vw;
}

.mobile .overlay-footer-links,
.company-info,
.overlay-footer-links-follow {
  position: absolute;
  z-index: 3;
  color: #fff;
  opacity: 1.1;
  text-align: center;
  word-spacing: 0.1rem;
  bottom: 60%;
}

.mobile .blog-footer-links {
  color: #fff;
  text-decoration: none;
}

.mobile .overlay-footer-links {
  bottom: 30%;
}

.company-info {
  top: 70%;
}

@media only screen
  and (max-width: 1024px)
  and (max-height: 1366px)
  and (-webkit-min-device-pixel-ratio: 1.5) {
  #progressBarContainer {
    top: 6%;
  }
}

@media only screen and (max-device-width: 768px) and (max-device-height: 1024px) {
  .blog-header {
    margin-top: 0;
    margin-bottom: 0.4rem !important;
  }

  #progressBarContainer {
    top: 6.8%;
  }

  .blog-footer-background-desktop {
    height: 40vh;
  }

  .col-4 .blog-footer {
    margin-left: -2rem;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) {
  #progressBarContainer {
    top: 10.5%;
  }
}

@media only screen and (max-device-width: 320px) {
  #navbar-brand-logo-blog {
    margin-left: -0.1rem !important;
  }

  .mobile .blog-footer-background-mobile {
    margin-top: 10rem;
    height: 90vh;
    width: 100vw;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) {
  #navbar-brand-logo-blog {
    margin-left: var(--spacing-40) !important;
  }
}

@media only screen and (min-width: 540px) and (min-height: 720px) {
  #navbar-brand-logo-blog {
    margin-left: var(--spacing-96) !important;
  }

  .blogs-wrapper .row .col-sm-4 .card {
    height: 22rem !important;
  }
}

@media only screen and (max-width: 768px) and (max-height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .blog-nav-options {
    margin-top: -0.7rem;
  }

  .blog-nav-options .btn-violet-outline {
    margin-right: 8rem !important;
  }

  .blog-nav-options .btn-violet-news {
    margin-left: 12rem !important;
  }
}

@media only screen and (min-device-width: 414px) and (min-device-height: 736px) {
  #navbar-brand-logo-blog {
    margin-left: var(--spacing-40) !important;
  }
}

@media (min-width: 768px) {
  .blogs-wrapper .row .col-sm-4 .card {
    height: 22rem !important;
  }
}

.icon-heart {
  width: var(--spacing-24);
  height: var(--spacing-24);
}

.icon-heart.active {
  -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
}

.scaleInImgFollow {
  -webkit-animation: scaleIn 0.75s 2;
          animation: scaleIn 0.75s 2;
}

.scaleInImgUnfollow {
  -webkit-animation: scaleIn 0.75s 1;
          animation: scaleIn 0.75s 1;
}

@-webkit-keyframes scaleIn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: none;
            filter: none;
  }

  50% {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
    -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: none;
            filter: none;
  }
}

@keyframes scaleIn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: none;
            filter: none;
  }

  50% {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
    -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: none;
            filter: none;
  }
}

.follow-blog {
  text-align: center;
  cursor: pointer;
  padding-top: var(--spacing-3);
}

.follow-blog .follower-length {
  color: #f42222;
  position: relative;
  top: -7px;
  left: var(--spacing-12);
  font-size: var(--font-size-bigger);
  -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
}

.catalysts-wrapper {
  border-radius: var(--spacing-24);
}

.catalysts-wrapper .main-section {
  position: relative;
  text-align: center;
  color: var(--color-white);
}

.catalysts-wrapper .main-section .navbar-brand {
  position: absolute;
  left: 0;
  width: 100%;
  margin: var(--spacing-32) 0;
}

.catalysts-wrapper .main-section img {
  width: 100%;
  overflow: hidden;
  object-fit: contain;
}

.catalysts-wrapper .catalyst-section img {
  width: 100%;
}

.catalysts-wrapper .main-section .navbar-brand-img img {
  max-width: 13vw;
}

img.catalyst_intro_banner {
  width: 100vw;
}

.catalysts-wrapper .main-section-mobile .overlay-heading {
  position: absolute;
  z-index: 3;
  top: 15%;
  left: 8.5%;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: var(--spacing-56);
  letter-spacing: var(--spacing-1);
  align-items: center;
  justify-content: center;
}

.catalysts-wrapper .main-section-mobile .navbar-brand-img-mobile {
  position: absolute !important;
  z-index: 3 !important;
  top: 4% !important;
  margin: var(--spacing-32) 0;
  left: 0;
  width: 100%;
}

.catalysts-wrapper .main-section-mobile .bottom-text-mobile .catalysts-text-mobile {
  position: absolute;
  top: 70%;
  font-family: "Nunito", sans-serif !important;
  line-height: var(--line-height);
}

.catalysts-wrapper .main-section-mobile .catalysts-btn {
  position: absolute;
  top: 83%;
  left: 35%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: var(--spacing-32);
  -webkit-filter: drop-shadow(0 1.92453px 1.92453px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 1.92453px 1.92453px rgba(0, 0, 0, 0.25));
  padding: var(--spacing-2) var(--spacing-16);
  color: var(--color-white);
  text-decoration: none;
  border: 0.86px solid;
  font-size: var(--font-size-big);
  height: var(--spacing-32);
}

.catalysts-wrapper .main-section .bottom-text {
  position: absolute;
  bottom: 4rem;
  width: 100%;
  left: 23%;
}

.catalysts-wrapper .main-section .catalysts-text {
  text-align: center;
  max-width: 50%;
  font-size: 1.25rem;
  font-family: 'Quicksand', sans-serif !important;
}

.catalysts-wrapper .main-section .catalysts-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
  -webkit-backdrop-filter: blur(34.3396px);
          backdrop-filter: blur(34.3396px);
  border-radius: var(--spacing-32);
  -webkit-filter: drop-shadow(0 3.43396px 3.43396px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 3.43396px 3.43396px rgba(0, 0, 0, 0.25));
  padding: var(--spacing-12) var(--spacing-40);
  color: var(--color-white);
  text-decoration: none;
  border: 0.86px solid;
  font-size: var(--font-size-big);
}

.catalysts-wrapper .catalyst-section {
  margin-top: var(--spacing-24);
}

.catalysts-wrapper .main-section-mobile {
  position: relative;
  text-align: center;
  color: var(--color-white);
  width: 100%;
}

/* Media queries */
@media only screen and (max-device-width: 320px) {
  .catalysts-wrapper .main-section-mobile .catalysts-btn {
    top: 85% !important;
  }

  .catalysts-wrapper .main-section-mobile .bottom-text-mobile .catalysts-text-mobile {
    top: 65% !important;
  }
}

@media only screen and (max-width: 280px) and (min-height: 653px) {
  .catalysts-wrapper .main-section-mobile .catalysts-btn {
    top: 80% !important;
    left: 30%;
    height: var(--spacing-24);
    padding-bottom: 1.7rem !important;
    font-size: var(--font-size-small);
  }

  .catalysts-wrapper .main-section-mobile .bottom-text-mobile .catalysts-text-mobile {
    top: 70% !important;
    font-size: var(--font-size-small);
  }
}

.catalysts-activities-wrapper {
  color: var(--color-white);
  background: radial-gradient(64.92% 58.09% at 50.04% 79.16%, #9a30db 1.5%, #41129e 100%);
  border-radius: var(--spacing-24);
  margin: var(--spacing-12) 0;
  position: relative;
}

.catalysts-activities-wrapper .close {
  position: absolute;
  right: 3%;
  top: 4%;
}

.catalysts-activities-wrapper .text {
  padding: var(--spacing-64);
}

.catalysts-activities-wrapper .header {
  font-family: 'Quicksand', sans-serif !important;
}

.catalysts-activities-wrapper .header-key {
  padding: var(--spacing-12) 0 0;
  font-family: 'Quicksand', sans-serif !important;
  font-weight: var(--font-weight-bold);
}

.catalysts-activities-wrapper .header-value {
  font-family: 'Quicksand', sans-serif !important;
  font-weight: 100;
  font-size: var(--font-size-bigger);
}

.catalysts-activities-wrapper .header-img {
  width: 80%;
}

.catalysts-activities-wrapper h1.header-mobile.pt-4 {
  position: absolute;
  left: 25%;
  font-size: var(--spacing-40);
}

.catalysts-activities-wrapper .text-center {
  padding: var(--spacing-40) !important;
}

.catalysts-activities-wrapper .close-mobile {
  position: absolute;
  right: 6%;
  top: 2%;
}

/* Media Queries */
@media only screen and (max-width: 280px) and (min-height: 653px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 20%;
  }
}

@media only screen and (min-width: 540px) and (min-height: 720px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 35% !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 31%;
  }
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 26%;
  }
}

@media only screen and (min-device-width: 414px) and (min-device-height: 736px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 29%;
  }
}

@media only screen and (max-device-width: 320px) {
  .catalysts-activities-wrapper h1.header-mobile.pt-4 {
    left: 21%;
  }
}

.catalysts-benefits-wrapper {
  color: var(--color-white);
  background: radial-gradient(64.92% 58.09% at 50.04% 79.16%, #9a30db 1.5%, #41129e 100%);
  border-radius: var(--spacing-24);
  margin: var(--spacing-12) 0;
  position: relative;
}

.catalysts-benefits-wrapper .close {
  position: absolute;
  right: 3%;
  top: 4%;
}

.catalysts-benefits-wrapper .text {
  padding: var(--spacing-64);
}

.catalysts-benefits-wrapper .header {
  font-family: 'Quicksand', sans-serif !important;
}

.catalysts-benefits-wrapper .header-key {
  padding: var(--spacing-12) 0 0;
  font-family: 'Quicksand', sans-serif !important;
  font-weight: var(--font-weight-bold);
}

.catalysts-benefits-wrapper .header-value {
  font-family: 'Quicksand', sans-serif !important;
  font-weight: 100;
  font-size: var(--font-size-bigger);
}

.catalysts-benefits-wrapper .header-img {
  width: 80%;
}

.catalysts-benefits-wrapper h1.header-mobile.pt-4 {
  position: absolute;
  left: 27%;
  font-size: var(--spacing-40);
}

.catalysts-benefits-wrapper .text-center {
  padding: var(--spacing-40) !important;
}

.catalysts-benefits-wrapper .close-mobile {
  position: absolute;
  right: 6%;
  top: 2%;
}

/* Media Queries */
@media only screen and (max-width: 280px) and (min-height: 653px) {
  .catalysts-benefits-wrapper h1.header-mobile.pt-4 {
    left: 20%;
  }
}

@media only screen and (min-width: 540px) and (min-height: 720px) {
  .catalysts-benefits-wrapper h1.header-mobile.pt-4 {
    left: 34% !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) {
  .catalysts-benefits-wrapper h1.header-mobile.pt-4 {
    left: 31%;
  }
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
  .catalysts-benefits-wrapper h1.header-mobile.pt-4 {
    left: 28%;
  }
}

@media only screen and (min-device-width: 414px) and (min-device-height: 736px) {
  .catalysts-benefits-wrapper h1.header-mobile.pt-4 {
    left: 29%;
  }
}

@media only screen and (max-device-width: 320px) {
  .catalysts-benefits-wrapper h1.header-mobile.pt-4 {
    left: 24%;
  }
}

.catalysts-expectations-wrapper {
  color: var(--color-white);

  /* height: 100vh; */
  background: radial-gradient(64.92% 58.09% at 50.04% 79.16%, #9a30db 1.5%, #41129e 100%);
  border-radius: var(--spacing-24);
  margin: var(--spacing-12) 0;
  text-align: center;
  position: relative;
}

.catalysts-expectations-wrapper .close {
  position: absolute;
  right: 3%;
  top: 4%;
}

.catalysts-expectations-wrapper .text {
  padding: var(--spacing-64) 0 0;
}

.catalysts-expectations-wrapper .header {
  font-family: 'Quicksand', sans-serif !important;
}

.catalysts-expectations-wrapper .header-key {
  padding: var(--spacing-12) 0 0;
  font-family: 'Quicksand', sans-serif !important;
  font-weight: var(--font-weight-bold);
}

.catalysts-expectations-wrapper .header-value {
  font-family: 'Quicksand', sans-serif !important;
  font-weight: 100;
  font-size: var(--font-size-bigger);
}

.catalysts-expectations-wrapper .header-img {
  width: 100%;
}

.catalysts-expectations-wrapper h1.header-mobile.pt-4 {
  position: absolute;
  left: 15%;
  font-size: var(--spacing-40);
}

.catalysts-expectations-wrapper .text-center {
  padding: var(--spacing-40) !important;
}

.catalysts-expectations-wrapper .close-mobile {
  position: absolute;
  right: 6%;
  top: 2%;
}

/* Media Queries */
@media only screen and (max-width: 280px) and (min-height: 653px) {
  .catalysts-expectations-wrapper h1.header-mobile.pt-4 {
    left: 20%;
  }
}

@media only screen and (min-width: 540px) and (min-height: 720px) {
  .catalysts-expectations-wrapper h1.header-mobile.pt-4 {
    left: 26.5% !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) {
  .catalysts-expectations-wrapper h1.header-mobile.pt-4 {
    left: 31%;
  }
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
  .catalysts-expectations-wrapper h1.header-mobile.pt-4 {
    left: 16%;
  }
}

@media only screen and (min-device-width: 414px) and (min-device-height: 736px) {
  .catalysts-expectations-wrapper h1.header-mobile.pt-4 {
    left: 20%;
  }
}

@media only screen and (max-device-width: 320px) {
  .catalysts-expectations-wrapper h1.header-mobile.pt-4 {
    left: 8%;
  }
}



.mobile-footer-wrapper .fixed-bottom {
  width: 100vw;
  background: var(--color-white);
  transition: 0.5s all ease-in-out;
}

.mobile-footer-wrapper {
  bottom: 0;
  width: 100vw;
  background-color: var(--color-white);
  padding: var(--spacing-16);
  text-align: center;
  z-index: 99;
  box-shadow: 0 0 10px  #ccc !important;
  margin-bottom: -3rem !important;
}

@media only screen and (max-width: 280px) {
  .mobile-footer-wrapper .btn {
    width: 7rem !important;
  }
}

.cookie-banner {
  width: 100vw;
  text-align: center;
}

button.btn-submit {
  background-color: #6930db;
  color: white;
  border-radius: 100px;
  width: 10rem;
}

button.btn-submit:hover {
  background-color: #492296;
  color: white;
}

