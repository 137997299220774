.catalysts-wrapper {
  border-radius: var(--spacing-24);
}

.catalysts-wrapper .main-section {
  position: relative;
  text-align: center;
  color: var(--color-white);
}

.catalysts-wrapper .main-section .navbar-brand {
  position: absolute;
  left: 0;
  width: 100%;
  margin: var(--spacing-32) 0;
}

.catalysts-wrapper .main-section img {
  width: 100%;
  overflow: hidden;
  object-fit: contain;
}

.catalysts-wrapper .catalyst-section img {
  width: 100%;
}

.catalysts-wrapper .main-section .navbar-brand-img img {
  max-width: 13vw;
}

img.catalyst_intro_banner {
  width: 100vw;
}

.catalysts-wrapper .main-section-mobile .overlay-heading {
  position: absolute;
  z-index: 3;
  top: 15%;
  left: 8.5%;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: var(--spacing-56);
  letter-spacing: var(--spacing-1);
  align-items: center;
  justify-content: center;
}

.catalysts-wrapper .main-section-mobile .navbar-brand-img-mobile {
  position: absolute !important;
  z-index: 3 !important;
  top: 4% !important;
  margin: var(--spacing-32) 0;
  left: 0;
  width: 100%;
}

.catalysts-wrapper .main-section-mobile .bottom-text-mobile .catalysts-text-mobile {
  position: absolute;
  top: 70%;
  font-family: "Nunito", sans-serif !important;
  line-height: var(--line-height);
}

.catalysts-wrapper .main-section-mobile .catalysts-btn {
  position: absolute;
  top: 83%;
  left: 35%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
  backdrop-filter: blur(5px);
  border-radius: var(--spacing-32);
  filter: drop-shadow(0 1.92453px 1.92453px rgba(0, 0, 0, 0.25));
  padding: var(--spacing-2) var(--spacing-16);
  color: var(--color-white);
  text-decoration: none;
  border: 0.86px solid;
  font-size: var(--font-size-big);
  height: var(--spacing-32);
}

.catalysts-wrapper .main-section .bottom-text {
  position: absolute;
  bottom: 4rem;
  width: 100%;
  left: 23%;
}

.catalysts-wrapper .main-section .catalysts-text {
  text-align: center;
  max-width: 50%;
  font-size: 1.25rem;
  font-family: 'Quicksand', sans-serif !important;
}

.catalysts-wrapper .main-section .catalysts-btn {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
  backdrop-filter: blur(34.3396px);
  border-radius: var(--spacing-32);
  filter: drop-shadow(0 3.43396px 3.43396px rgba(0, 0, 0, 0.25));
  padding: var(--spacing-12) var(--spacing-40);
  color: var(--color-white);
  text-decoration: none;
  border: 0.86px solid;
  font-size: var(--font-size-big);
}

.catalysts-wrapper .catalyst-section {
  margin-top: var(--spacing-24);
}

.catalysts-wrapper .main-section-mobile {
  position: relative;
  text-align: center;
  color: var(--color-white);
  width: 100%;
}

/* Media queries */
@media only screen and (max-device-width: 320px) {
  .catalysts-wrapper .main-section-mobile .catalysts-btn {
    top: 85% !important;
  }

  .catalysts-wrapper .main-section-mobile .bottom-text-mobile .catalysts-text-mobile {
    top: 65% !important;
  }
}

@media only screen and (max-width: 280px) and (min-height: 653px) {
  .catalysts-wrapper .main-section-mobile .catalysts-btn {
    top: 80% !important;
    left: 30%;
    height: var(--spacing-24);
    padding-bottom: 1.7rem !important;
    font-size: var(--font-size-small);
  }

  .catalysts-wrapper .main-section-mobile .bottom-text-mobile .catalysts-text-mobile {
    top: 70% !important;
    font-size: var(--font-size-small);
  }
}
